@import url('https://fonts.googleapis.com/css?family=Poppins');


.App {
  text-align: center;
  display: flex;
  flex-wrap: nowrap;
  overflow: hidden;
  justify-content: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

html, body {
  height: 100%;
  overflow: auto;
  width: 100%;
}

body {
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: #6a8483;
}

main {
  padding: 10px;
}

.main {
  display: contents;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.schedule-page {
  /* background-color: #61dafb; */
  /* min-height: 100vh; */
  /* height: 100%;
  color: white; */
  width: -webkit-fill-available;
  background-color: #6a8483;
  height: 100%;
  width: 100%;
}

.rowC {
  display: flex;
  flex-direction: row;
  border-color: black;
  border-style: solid;
  border-width: 3;
  justify-content: space-between;
}

.key {
  margin-left: 10%;
  margin-right: 10%;
}

.value {
  margin-left: 10%;
  margin-right: 10%;
}

.listAndMap {
  display: flex;
  min-height: 700px;
  padding: 10px 10px;
}

.main-wrapper {
  height: 60%;
  margin: 10px 50px;
  filter: drop-shadow(-1px 5px 3px #ccc);
}
.info-wrapper {
  margin-top: 15px;
}
.map-details {
  text-align: center;
  font-size: 1.2em;
}
.map-details span {
  font-weight: bold;
}
.search-input {
  font-size: 1.2em;
  width: 80%;
}

.leaflet-container {
  /* width: 80%; */
  min-width: 100%;
  height: 80%;
  /* margin: 10px 40px 10px 40px; */
  z-index: 1;
}


/* global styling */
.leaflet-control-geosearch *,
.leaflet-control-geosearch *:before,
.leaflet-control-geosearch *:after {
  box-sizing: border-box;
}

/* leaflet button styling */
.leaflet-control-geosearch .leaflet-bar-part {
  border-radius: 4px;
  border-bottom: none;
}

.leaflet-control-geosearch a.leaflet-bar-part:before,
.leaflet-control-geosearch a.leaflet-bar-part:after {
  position: absolute;
  display: block;
  content: '';
}

/* magnifying glass */
.leaflet-control-geosearch a.leaflet-bar-part:before {
  top: 19px;
  left: 16px;
  width: 8px;
  border-top: 2px solid #555;
  transform: rotateZ(45deg);
}

.leaflet-control-geosearch a.leaflet-bar-part:after {
  top: 6px;
  left: 6px;
  height: 14px;
  width: 14px;
  border-radius: 50%;
  border: 2px solid #555;
}

/* resets for pending and error icons */
.leaflet-control-geosearch.error a.leaflet-bar-part:before,
.leaflet-control-geosearch.pending a.leaflet-bar-part:before {
  display: none;
}

.leaflet-control-geosearch.pending a.leaflet-bar-part:after,
.leaflet-control-geosearch.error a.leaflet-bar-part:after {
  left: 50%;
  top: 50%;
  width: 18px;
  height: 18px;
  margin: -9px 0 0 -9px;
  border-radius: 50%;
}

/* pending icon */
.leaflet-control-geosearch.pending a.leaflet-bar-part:after {
  content: '';
  border: 2px solid #555;
  border-top: 2px solid #f3f3f3;
  animation: spin 1s linear infinite;
}

/* error icon */
.leaflet-control-geosearch.error a.leaflet-bar-part:after {
  content: '!';
  line-height: initial;
  font-weight: 600;
  font-size: 18px;
  border: none;
}

/* search form styling */
.leaflet-control-geosearch form {
  display: none;
  position: absolute;
  top: -2px;
  left: 28px;
  border-radius: 0 4px 4px 0;
  border: 2px solid rgba(0, 0, 0, 0.2);
  border-left: none;
  background-color: #fff;
  background-clip: padding-box;
  z-index: -1;
  height: auto;
  margin: 0;
  padding: 0 8px;
}

.leaflet-control-geosearch.active form {
  display: block;
}

.leaflet-control-geosearch form input {
  min-width: 200px;
  width: 100%;
  border: none;
  outline: none;
  margin: 0;
  padding: 0;
  font-size: 12px;
  height: 30px;
  border-radius: 0 4px 4px 0;
  text-indent: 8px;
}

.leaflet-control-geosearch .results {
  background: #fff;
}

.leaflet-control-geosearch .results > * {
  line-height: 24px;
  padding: 0 8px;
  border: 1px solid transparent;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.leaflet-control-geosearch .results.active {
  padding: 8px 0;
  border-top: 1px solid #c6c6c6;
}

.leaflet-control-geosearch .results > .active,
.leaflet-control-geosearch .results > :hover {
  background-color: #f8f8f8;
  border-color: #c6c6c6;
  cursor: pointer;
}

/* add missing border to form */
.leaflet-control-geosearch .results.active:after {
  content: '';
  display: block;
  width: 0;
  border-left: 2px solid rgba(0, 0, 0, .2);
  position: absolute;
  left: -2px;
  bottom: -2px;
  top: 30px;
}

/* animations */
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.leaflet-top .leaflet-control-geosearch.bar,
.leaflet-bottom .leaflet-control-geosearch.bar {
  display: none;
}

.leaflet-control-geosearch.bar {
  position: relative;
  display: block;
  height: auto;
  width: 400px;
  margin: 10px auto 0;
  cursor: auto;
  z-index: 1000;
}

.leaflet-control-geosearch.bar form {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  border: 2px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}

.leaflet-control-geosearch.bar form input {
  min-width: 100%;
  width: 100%;
}

.leaflet-control-geosearch.bar .results.active:after {
  opacity: .2;
}

.leaflet-right .leaflet-control-geosearch form {
  right: 28px;
  left: initial;
  border-radius: 4px 0 0 4px;
  border-left: inherit;
  border-right: none;
}

.leaflet-control-geosearch a.reset {
  color: black;
  position: absolute;
  line-height: 30px;
  padding: 0 8px;
  right: 0;
  top: 0;
  cursor: pointer;
  border: none;
}

.leaflet-control-geosearch a.reset:hover {
  background: #f5f5f5;
}

.miles {
  display: flex; 
  justify-content: center;
  margin-top: 20px;
  align-items: center;
  width: 50%;
  max-height: 65px;
}

.tripPlannerItems {
  /* display: 'inline-block'; */
  text-align: -webkit-center;
}

#demo-simple-select {
  background-color:#425b62;
  color: white;
  padding: 6px 8px;
}

#demo-simple-select-label {
  color: white;
}

#standard-basic {
  text-align: center;
}

#standard-basic-miles {
  color: white;
  padding-top: 20px;
}

.gm-style .gm-style-iw-c {
  padding-right: 10px !important;
  padding-bottom: 0px !important;
  max-width: 500px !important;
  max-height: 326px !important;
  min-width: 350px !important;
  position: absolute;
  box-sizing: border-box;
  overflow: hidden;
  top: 0;
  left: 0;
  transform: translate(-50%,-100%);
  background-color: #6a8483;
  border-radius: 8px;
  box-shadow: 0 2px 7px 1px rgba(0,0,0,0.3);
}

.gm-style-iw-d {
  overflow: hidden !important;
}

.gm-style-iw-tc::after {
  background: #6a8483 !important;
}

.gm-ui-hover-effect span {
  background-color: white;
}

.schoolName {
  margin: 10px 0 0 0;
  font-family: "Geogrotesque","Rockwell",Georgia,Times,"Times New Roman",serif;
  font-size: .8rem;
  font-weight: 600;
  text-transform: uppercase;
  color: white;
  /* padding: 1px 0 0 0;
  font-family: "Geogrotesque","Rockwell",Georgia,Times,"Times New Roman",serif;
  font-size: 1.5rem;
  font-weight: 600;
  text-transform: uppercase; */
}

.teamAndLogo {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.selectSchool {
  color: white;
  margin: 10px 0 0 0;
  font-family: "Geogrotesque","Rockwell",Georgia,Times,"Times New Roman",serif;
  font-size: 1.3rem;
  font-weight: 600;
  text-transform: uppercase;
}

.mileRadius {
  color: white;
  margin-left: 20px;
}

.h2Text {
  font-size: 2.5rem;
  margin: 0 0 5px 0;
  /* font-family: "Geogrotesque","Rockwell",Georgia,Times,"Times New Roman",serif; */
  font-family: 'Poppins';
  /* font-size: 1.875rem; */
  font-weight: 600;
  /* text-transform: uppercase; */
  color:white;
}

.h2TextSmaller {
  font-size: medium;
  margin: 4px 0 4px 0;
  /* font-family: "Geogrotesque","Rockwell",Georgia,Times,"Times New Roman",serif; */
  /* font-family: 'Poppins'; */
  /* font-size: 1.875rem; */
  font-weight: 200;
  /* text-transform: uppercase; */
  color:white;
  font-weight: bold;
}

.bold-date {
  font-weight: bold;
}

.grey-background {
  background-color: #edeef0;
}

.school-details {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  padding: 34px 0 40px 0;
  margin: 0;
}

.d1-group {
  flex: 1;
  padding: 0 8px 7px 8px;
  text-align: center;
}

.column-name {
  padding: 1px 0 0 0;
  font-family: "Geogrotesque","Rockwell",Georgia,Times,"Times New Roman",serif;
  font-size: .75rem;
  font-weight: 500;
  text-transform: uppercase;
  color: white;
}

.column-value {
  margin: 10px 0 0 0;
  font-family: "Geogrotesque","Rockwell",Georgia,Times,"Times New Roman",serif;
  font-size: 1.875rem;
  font-weight: 600;
  text-transform: uppercase;
}

.alignSchoolList {
  text-align-last: center;
}

#schoolList {
  display: inline-block;
  background-color: #6a8483;
  overflow-y: scroll;
  max-height: 650px;
}

.buttonOne {
  background-color: #5dbea3;
  color: white;
}

.buttonTwo {
  background-color: green;
  color: white;
}

.rowSchoolList {
  display: grid;
  width: 100%;
}

#stadiumInfoPaper {
  display: inline-block;
  background-color: #6a8483;
  /* overflow-y: scroll; */
  /* height: 650px; */
}

/* #tripPlannerPaper {
  display: inline-block;
  background-color: #6a8483;
  height: 650px;
  min-height: 100vh;
  min-width: 100vw;
} */

#location {
  color: white;
  background-color: #425b62;
  padding: 8px 8px;
}

#locationSearchButton {
  /* color: white;
  background-color: #425b62; */
  /* padding: 8px 8px; */
  height: 39px;
  margin-left: 2px;
}

#stadiumPanelText {
  padding: 1px 0 0 0;
  font-family: "Geogrotesque","Rockwell",Georgia,Times,"Times New Roman",serif;
  font-size: .75rem;
  font-weight: 500;
  text-transform: uppercase;
}

.css-i4bv87-MuiSvgIcon-root {
  /* color: white; */
  color: black;
}

.MuiAutocomplete-popper {
  background-color: black;
}

.infoPanel {
  background-color: #6a8483;
  min-height: 700px;
  min-width: 250px;
}

#combo-box-demo-label {
  color: white;
}

#mapPaper {
  width: 100%;
  height: 100%;
  background-color: #6a8483;
}

.container {
  margin-left: 0;
  margin-right: 0;
}

#mapContainer {
  /* width: 700px; */
  height: 700px;
}

.dateButton {
  margin-right: 0;
  height: 100%;
}

.css-1d6wzja-MuiButton-startIcon {
  margin-right: 0;
}

.css-13cymwt-control {
  background-color:#425b62 !important;
}

.css-t3ipsp-control {
  background-color:#425b62 !important;
}

.css-1dimb5e-singleValue {
  color: white !important;
}

.css-1jqq78o-placeholder {
  color: white !important;
}

.selector css-b62m3t-container {
  width: 50%;
}

.css-1nmdiq5-menu {
  background-color:#425b62 !important;
  color: white !important;
  width: 50% !important;
}

.css-d7l1ni-option {
  background-color: #61dafb !important;
}

.css-1nmdiq5-menu::after {
  background-color:#425b62 !important;
  color: green !important;
}

#react-select-2-input {
  color: white !important;
}

.css-ogygkx-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
  top: 0 !important;
}

.css-loqjcv-MuiFormLabel-root-MuiInputLabel-root {
  top: -8 !important;
}

.css-ogygkx-MuiFormLabel-root-MuiInputLabel-root {
  top: 0 !important;
}

.css-1mb0oxk-MuiSlider-root .MuiSlider-valueLabel {
  border-radius: 40% 50% 40% 0 !important;
  width: 38px;
  height: 38px;
}

@media (max-width: 600px) {
  .App {
    text-align: center;
    display: flex;
    flex-wrap: nowrap;
    overflow: hidden;
    /* min-height: 100vh;
    height: 100vh; */
  }
  #mapPaper {
    background-color: #6a8483;
    min-height: 100%;
    height: 100%;
    width: 100%;
  }
  .schedule-page {
    min-height: 100%;
  }
  .rootContainer {
    padding: 0;
  }
  .container {
    max-width: 100%;
    max-height: 100%;
  }
  #tripPlannerPaper {
    /* display: inline-block; */
    background-color: #6a8483;
    max-width: 100%;
    max-height: 100%;
    min-height: 100%;
  }
  .leafletMapContainer {
    min-height: 100%;
    min-width: 100%;
  }
  #mapContainer {
    max-height: 100%;
    /* height: 59%; */
    position: absolute;
  }
  .css-13cymwt-control {
    width: 50%;
  }
  .css-t3ipsp-control {
    width: 50%;
  }
  .css-b62m3t-container {
    display: flex;
    justify-content: center;
    position: absolute !important;
    width: 80%;
  }
  .css-166bipr-Input {
    color: white !important;
  }
  .css-tr4s17-option {
    background-color: transparent !important;
  }
  .leafletMapHolder {
    /* min-width: 200px;
    max-width: 100%;
    max-height: 100%; */
    width: 500px;
    height: 500px;
  }
  .gm-style .gm-style-iw-c {
    padding-right: 10px !important;
    padding-bottom: 0px !important;
    max-width: 350px !important;
    max-height: 326px !important;
    min-width: 200px !important;
    position: absolute;
    box-sizing: border-box;
    overflow: hidden;
    top: 0;
    left: 0;
    transform: translate(-50%,-100%);
    background-color: #6a8483;
    border-radius: 8px;
    box-shadow: 0 2px 7px 1px rgba(0,0,0,0.3);
  }
  .SearchAndDate {
    width: 80% !important;
  }
  .reset-date {
    text-align: end;
    padding-right: 10%;
    background-color: #425b62;
    color: white;
  }
  .vivid-seats-ticket-link {
    height: 100%;
    width: 95%;
    margin-top: 6px;
  }
}

@media (min-width: 601px) {
  .leafletMapContainer {
    min-height: 600px;
    min-width: 800px;
  }
  #tripPlannerPaper {
    display: inline-block;
    background-color: #6a8483;
    /* width: 800px;
    height: 650px; */
    /* width: 95%; */
    width: 70%;
    height: 100%;
  }
  .tripPlannerItems {
    display: inline;
    text-align: -webkit-center;
  }
  #mapContainer {
    min-width: 200px;
    max-width: 100%;
    max-height: 100%;
    height: 300px;
  }
  .App {
    text-align: center;
    display: flex;
    flex-wrap: nowrap;
    overflow: hidden;
    justify-content: center;
    height: 100vh;
  }
  .find-button {
    width: 10% !important;
    color: white !important;
    right: 45% !important;
  }
  .away-game-map {
    margin-top: 10px;
  }
  .css-b62m3t-container {
    display: flex;
    justify-content: center;
  }
  .css-13cymwt-control {
    width: 50%;
  }
  .css-t3ipsp-control {
    width: 50%;
  }
  .css-166bipr-Input {
    color: white !important;
  }
  .css-tr4s17-option {
    background-color: transparent !important;
  }
  .SearchAndDate {
    width: 40% !important;
  }
  .reset-date {
    text-align: center;
    padding-left: 200px;
    background-color: #425b62;
    color: white;
  }
  .vivid-seats-ticket-link {
    margin-top: 6px;
    width: 90%;
    height: 100%;
  }
  .list-button {
    display: none !important;
  }
  .game-list-drawer {
    display: none;
  }
  /* .selector {
    background-color: #425b62;
  } */
}
.away-game-map {
  margin-top: 10px;
}

/* Try this text css out */
/* padding: 1px 0 0 0;
font-family: "Geogrotesque","Rockwell",Georgia,Times,"Times New Roman",serif;
font-size: .75rem;
font-weight: 500;
text-transform: uppercase; */