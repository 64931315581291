.RenderMap {
  height: 100%;
  min-height: 100%;
  width: 100%;
  min-width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.map-container {
  height: 95%;
  width: 95%;
}

.button-bottom {
  position: sticky;
  bottom: 0px;
  width: 100%;
}

.map-container-unselected {
  /* height: calc(100% - 60px);
  min-height: calc(100% - 60px);
  max-height: calc(100% - 60px); */
  width: 95%;
  height: 80%;
  position: relative;
}

.map-container {
  /* height: 100vh;
  width: 100vh; */
  /* height: calc(100% - 94px);
  min-height: calc(100% - 94px);
  max-height: calc(100% - 94px);
  width: 100%; */
  width: 95%;
  height: 80%;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (max-width: 600px) {
  .RenderMap {
    height: calc(100% - 136.5px);
    min-height: calc(100% - 136.5px);
    max-height: calc(100% - 136.5px);
    width: 100%;
  }
  .RenderMapUnselected {
    height: calc(100% - 60px);
    min-height: calc(100% - 60px);
    max-height: calc(100% - 60px);
    width: 100%;
  }
  .map-container {
    /* height: 100vh;
    width: 100vh; */
    height: calc(100% - 169.4px);
    min-height: calc(100% - 169.4px);
    max-height: calc(100% - 169.4px);
    width: 100%;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .away-schedule-team {
    height: calc(100% - 158.45px);
    min-height: calc(100% - 158.45px);
    max-height: calc(100% - 158.45px);
    width: 100%;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .away-schedule-no-team {
    height: calc(100% - 64px);
    min-height: calc(100% - 64px);
    max-height: calc(100% - 64px);
    width: 100%;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

  @media (min-width: 601px) and (max-width: 2500px) {
    .map-container {
      height: 75%;
      width: 95%;
      /* position: absolute; */
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      /* margin-top: 10px; */
    }
    .RenderMap {
      height: 100%;
      min-height: 100%;
      width: 100%;
      min-width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 10px;
      position: relative;
    }
    .away-schedule-team {
      height: calc(100% - 158.45px);
      min-height: calc(100% - 158.45px);
      max-height: calc(100% - 158.45px);
      width: 95%;
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .away-schedule-no-team {
      height: calc(100% - 64px);
      min-height: calc(100% - 64px);
      max-height: calc(100% - 64px);
      width: 95%;
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
